@import '@carbon/themes/scss/themes';
$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import 'carbon-components/scss/globals/scss/styles.scss';

a {
  color: $text-04;
}

.bx--side-nav__navigation {
  background-color: #4a4b46;
}

a.bx--side-nav__link--current,
a.bx--side-nav__link--current > span.bx--side-nav__link-text {
  background-color: #050505;
  color: $text-04;
}

.bx--side-nav__item:not(.bx--side-nav__item--active)
  > .bx--side-nav__link:hover {
  background-color: #050505;
  color: $text-04;
}

a.bx--side-nav__link > .bx--side-nav__link-text,
.bx--side-nav__item:not(.bx--side-nav__item--active)
  > .bx--side-nav__link:hover
  > span {
  color: $text-04;
}





